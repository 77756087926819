/* eslint-disable @typescript-eslint/naming-convention */
export const MSAL_CLIENT_ID = `${process.env.REACT_APP_MSAL_CLIENTID}`;
export const MSAL_AUTHORITY = `${process.env.REACT_APP_MSAL_AUTHORITY}`;
export const MSAL_SCOPE = `${process.env.REACT_APP_READ_SCOPE}`;
export const AI_INSTRUMENTATION_KEY = `${process.env.REACT_APP_AI_INSTRUMENTATION_KEY}`;
export const INLINE_RUNTIME_CHUNK=false

//Azure b2c config
//base
export const AZURE_B2C_CLIENT_ID = `${process.env.REACT_APP_AZURE_B2C_CLIENT_ID}`;
export const AZURE_B2C_AUTHORIZATION_BASE_URL = `${process.env.REACT_APP_AZURE_B2C_AUTHORIZATION_BASE_URL}`;
export const AZURE_B2C_ACCESS_TOKEN_ENDPOINT = `${process.env.REACT_APP_AZURE_B2C_ACCESS_TOKEN_ENDPOINT}`;
export const AZURE_B2C_SCOPE = `${process.env.REACT_APP_AZURE_B2C_SCOPE}`;
export const AZURE_B2C_SCOPE_PORTAL = `${process.env.REACT_APP_AZURE_B2C_SCOPE_PORTAL}`;
export const AZURE_B2C_LOGS_ENABLED = `${process.env.REACT_APP_AZURE_B2C_LOGS_ENABLED}`;
export const AZURE_B2C_LOGOUT_URL = `${process.env.REACT_APP_AZURE_B2C_LOGOUT_URL}`;
//Web
export const AZURE_B2C_WEB_RESPONSE_TYPE = `${process.env.REACT_APP_AZURE_B2C_WEB_RESPONSE_TYPE}`;
export const AZURE_B2C_WEB_PKCE_ENABLED = `${process.env.REACT_APP_AZURE_B2C_WEB_PKCE_ENABLED}`;
export const AZURE_B2C_WEB_ACCESS_TOKEN_ENDPOINT = `${process.env.REACT_APP_AZURE_B2C_WEB_ACCESS_TOKEN_ENDPOINT}`;
export const AZURE_B2C_WEB_REDIRECT_URL = `${process.env.REACT_APP_AZURE_B2C_WEB_REDIRECT_URL}`;
export const AZURE_B2C_WEB_REDIRECT_URL_PORTAL = `${process.env.REACT_APP_AZURE_B2C_WEB_REDIRECT_URL_PORTAL}`;
export const AZURE_B2C_WEB_POST_LOGOUT_REDIRECT_URL = `${process.env.REACT_APP_AZURE_B2C_WEB_POST_LOGOUT_REDIRECT_URL}`;
export const AZURE_B2C_WEB_WINDOW_OPTIONS = `${process.env.REACT_APP_AZURE_B2C_WEB_WINDOW_OPTIONS}`;
export const AZURE_B2C_WEB_WINDOW_TARGET = `${process.env.REACT_APP_AZURE_B2C_WEB_WINDOW_TARGET}`;
//Android
export const AZURE_B2C_ANDROID_RESPONSE_TYPE = `${process.env.REACT_APP_AZURE_B2C_WEB_RESPONSE_TYPE}`;
export const AZURE_B2C_ANDROID_PKCE_ENABLED = `${process.env.REACT_APP_AZURE_B2C_WEB_PKCE_ENABLED}`;
export const AZURE_B2C_ANDROID_REDIRECT_URL = `${process.env.REACT_APP_AZURE_B2C_ANDROID_REDIRECT_URL}`;
export const AZURE_B2C_ANDROID_LOGOUT_REDIRECT_URL = `${process.env.REACT_APP_AZURE_B2C_ANDROID_POST_LOGOUT_REDIRECT_URL}`;
export const AZURE_B2C_ANDROID_TOKEN_ENDPOINT = `${process.env.REACT_APP_AZURE_B2C_ANDROID_ACCESS_TOKEN_ENDPOINT}`;
export const AZURE_B2C_ANDROID_RESULT_ON_NEWINTENT = `${process.env.REACT_APP_AZURE_B2C_ANDROID_HANDLE_RESULT_ON_NEWINTENT}`;
export const AZURE_B2C_ANDROID_RESULT_ON_ACTIVITYRESULT = `${process.env.REACT_APP_AZURE_B2C_ANDROID_HANDLE_RESULT_ON_ACTIVITYRESULT}`;
//IOS
export const AZURE_B2C_IOS_RESPONSE_TYPE = `${process.env.REACT_APP_AZURE_B2C_IOS_RESPONSE_TYPE}`;
export const AZURE_B2C_IOS_PKCE_ENABLED = `${process.env.REACT_APP_AZURE_B2C_IOS_PKCE_ENABLED}`;
export const AZURE_B2C_IOS_REDIRECT_URL = `${process.env.REACT_APP_AZURE_B2C_IOS_REDIRECT_URL}`;
export const AZURE_B2C_IOS_POST_LOGOUT_REDIRECT_URL = `${process.env.REACT_APP_AZURE_B2C_IOS_POST_LOGOUT_REDIRECT_URL}`;
export const AZURE_B2C_IOS_ACCESS_TOKEN_ENDPOINT = `${process.env.REACT_APP_AZURE_B2C_IOS_ACCESS_TOKEN_ENDPOINT}`;

//TODO: update to use tokenized values
export const ABP_DOMAIN_HINT = "apteanid.onmicrosoft.com"; //`${process.env.REACT_APP_ABP_DOMAIN_HINT}`;

export const API_BASE_ADDRESS = "https://webappsprod.eam.apteancloud.com/api/"; //`${process.env.REACT_APP_API_BASE_ADDRESS}`;
export const MOBILE_BOOTSTRAP_API_BASE_ADDRESS = "https://prod-portalapi.eam.apteancloud.com/api/"; //`${process.env.REACT_APP_MOBILE_BOOTSTRAP_API_BASE_ADDRESS}`;
